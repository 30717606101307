{
  "name": "scw-action-tracker",
  "version": "1.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local",
    "build": "ng build",
    "watch": "ng build --watch",
    "test": "ng test --browsers ChromeHeadless"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.7",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.7",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@microsoft/applicationinsights-web": "^3.2.0",
    "@ngrx/component-store": "^17.2.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular2-multiselect-dropdown-ivy": "^1.0.4",
    "dayjs": "^1.11.11",
    "lodash": "^4.17.21",
    "moment-timezone": "^0.5.45",
    "ngx-daterangepicker-material": "^6.0.4",
    "ngx-editor": "^17.5.4",
    "ngx-webcam": "^0.4.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.6",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.3.0",
    "@angular/language-service": "^17.3.7",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.1",
    "@typescript-eslint/eslint-plugin": "^6.0.0",
    "@typescript-eslint/parser": "^6.0.0",
    "eslint": "^8.42.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.3",
    "eslint-plugin-prettier": "^5.0.0",
    "eslint-plugin-sonarjs": "^0.25.1",
    "eslint-plugin-typescript-sort-keys": "^3.2.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.0.0",
    "prettier-plugin-organize-imports": "^3.2.4",
    "typescript": "~5.4.2"
  }
}
